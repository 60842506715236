export const editable_config = {
    Admin_Name : "Zabiha App",
    Logo : require("../src/assests/chicken_logo.png"),
    Main_Color : "#00922D" , // 007FFF #28A75A
    Hover_Color:"#027024",  // #06973f
    ImageUploadSize:1000000, // 1 mb 
    CategoryImageUploadSize:1000000, // 1 mb 
    BannerUploadSize:1000000, // 1 mb ,
    Currency_symbol:'$',
    Privacy_Policy_Link:'https://adiogent.in/privacy-policy/',
    TermAndServiceLink:'https://adiogent.in/term-and-conditions/',
    Contact_Support_Link:'https://adiogent.in/support-for-dash/',
    App_Publish_Support_Link:' https://adiogent.in/support-for-publish-app/',
    Tutorials_Link:'https://adiogent.in/video-training-and-tutorials/',
    ImageCompressorLink:'https://imagecompressor.in/',
    FRONTEND_VALIDATOR:'iwerbxinuwfxawuxdubfjsaknbfkjsgfabewubinkjbmayankandsanjeevbdhiyahingeuinhrcuiashuichraweiubrcuiwaenhfiucnbwaeiufnciuwefbnciusbonficsnabifxkmasfacfnbsakxfbmascaca',
}