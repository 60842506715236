export default {

    availablecolors:[
    'black',
    'silver',
    'gray',
    'white',
    'maroon',
    'red',	
    'purple',	
    'green',	
    'lime',	
    'olive',	
    'yellow',	
    'navy',
    'blue',	
    'teal',		
    'aqua',	
    'orange',	
    'aliceblue',	
    'antiquewhite',	
    'aquamarine',	
    'azure',	
    'beige',	
    'bisque',	
    'blanchedalmond',	
    'blueviolet',	
    'brown',	
    'burlywood',		
    'cadetblue',	
    'chartreuse',		
    'chocolate',	
    'coral',	
    'cornflowerblue',	
    'cornsilk',	
    'crimson',		
    'cyan',	
    'darkblue',	
    'darkcyan',	
    'darkgoldenrod',		
    'darkgray',	
    'darkgreen',		
    'darkkhaki',	
    'darkmagenta',	
    'darkolivegreen',	
    'darkorange',	
    'darkorchid',	
    'darkred',	
    'darksalmon',	
    'darkseagreen',	
    'darkslateblue',		
    'darkslategray',	
    'darkslategrey',	
    'darkturquoise',		
    'darkviolet',	
    'deeppink',	
    'deepskyblue',		
    'dimgray',		
    'dimgrey',		
    'dodgerblue',	
    'firebrick',		
    'floralwhite',		
    'forestgreen',	
    'gainsboro',	
    'ghostwhite',	
    'gold',	
    'goldenrod',		
    'greenyellow',		
    'grey',	
    'honeydew',		
    'hotpink',		
    'indianred',	
    'indigo',		
    'ivory',	
    'khaki',	
    'lavender',	
    'lavenderblush',	
    'lawngreen',	
    'lemonchiffon',	
    'lightblue',		
    'lightcoral',	
    'lightcyan',	
    'lightgray',	
    'lightgreen',		
    'lightpink',	
    'lightsalmon',		
    'lightseagreen',	
    'lightskyblue',	
    'lightsteelblue',	
    'lightyellow',	
    'limegreen',		
    'magenta',	
    'pink',	
    'plum',	
    'powderblue',	
    'rosybrown',		
    'royalblue',	
    'saddlebrown',		
    'salmon',	
    'sandybrown',	
    'seagreen',	
    'seashell',	
    'sienna',	
    'skyblue',	
    'slateblue',	
    'slategray',	
    'slategrey',	
    'snow',	
    'springgreen',	
    'steelblue',	
    'tan',	
    'thistle',	
    'tomato',	
    'turquoise',	
    'violet',	
    'wheat',		
      ],
    
// availablesizes : [
//         'S',
//         'M',
//         'L',
//         'XL',
//         'XXL'
//       ],

// availableWeight : [
//         '500g',
//         '900g',
//         '1kg',
//         '1.5kg',
//         '1.7kg',
//         '2kg',
//         '2.5kg',
//         '3kg',
//         '4kg',
//         '5kg',
//       ]

}