import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop(theme) {
  const varLow = alpha(theme.palette.grey[900], 0.48);
  const varHigh = alpha(theme.palette.grey[900], 1);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter:'blur(2px)',
          background: [
            `#0000002b`,
            // `rgb(22,28,36)`,
            // `-moz-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,  
            // `-webkit-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
            // `linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
            // 'blur(2px)',
          ],
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
            backdropFilter:'none',
          },
        },
      },
    },
  };
}
